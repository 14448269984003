/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*[Mastering React Memo - Jack Herrington](https://www.youtube.com/watch?v=DEPwA3mv_R8)*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Container} from "@chakra-ui/react";
import {YouTube, Gist} from "mdx-embed";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    code: "code",
    h2: "h2",
    h3: "h3",
    blockquote: "blockquote",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "memo")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "useMemo")), "\n"), "\n", React.createElement(_components.h2, null, React.createElement(_components.code, null, "memo")), "\n", "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubeId: "DEPwA3mv_R8"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "compares primitive values"), "\n"), "\n", React.createElement(_components.h3, null, "High-Level Guideline and Rules For ", React.createElement(_components.code, null, "memo")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Render if the props have changed"), "\n"), "\n", React.createElement(_components.h2, null, React.createElement(_components.code, null, "useMemo")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "comparing object/arrays"), "\n", React.createElement(_components.li), "\n"), "\n", React.createElement(_components.h2, null, "Looking Into ReRendering"), "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubeId: "ARWX1XdghLk"
  })), "\n", React.createElement(_components.h2, null, "CheatSheet"), "\n", React.createElement(_components.h2, null, "Catch"), "\n", React.createElement(_components.h3, null, "setTimeout and setInterval"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://daily-dev-tips.com/posts/react-cleaner-use-of-settimeout/"
  }, "react cleaner use setTimout - daily dev tips")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://codedamn.com/news/reactjs/how-to-use-settimeout-in-react-complete-guide-with-examples"
  }, "setTimeout example - codedamn")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
